import { usePDF } from "@react-pdf/renderer";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  useFlowContext,
  useFlowDispatchContext,
} from "../../context/FlowContext";
import { useUserContext } from "../../context/UserContext";
import { getPackageById } from "../../services/ida";
import { setUserPackage } from "../../services/ida";
import { sendCmpEvent } from "../../services/tracking";
import { encodeBase64, isDesktopView } from "../../services/utils";
import Button from "../Button";
import LoaderScreen from "../LoaderScreen";
import Modal from "../Modal";
import PdfPreview from "../PdfPreview";
import PdfWillPreview from "../PdfWillPreview";
import ProgressBar from "../ui/ProgressBar";
import RightColumnHeader from "./RightColumnHeader";

export default function Package() {
  const [loading, setLoading] = useState(true);
  const [paket, setPaket] = useState();
  const [previewModal, setPreviewModal] = useState(false);
  const [previewFile, setPreviewFile] = useState(null);

  const navigate = useNavigate();
  const { user } = useUserContext();
  const { flow } = useFlowContext();
  const dispatchFlow = useFlowDispatchContext();

  const [pdfIns] = usePDF({
    document: (
      <PdfWillPreview
        name={`${user.info?.firstName} ${user.info?.lastName}`}
        personNumber={user.profile ? user.profile.personNumber : ""}
      />
    ),
  });

  useEffect(() => {
    if (!user.accessToken) {
      return;
    }
    if (user.accessToken && flow.inv && user.package) {
      setPaket({
        price: parseInt(user.package.total),
      });
      setLoading(false);
      return;
    }
    if (user.accessToken && flow.pkg) {
      const pid = flow.inv ? flow.inv.p : flow.pkg;
      getPackageById(user.accessToken, pid)
        .then((data) => {
          setPaket({
            price: parseInt(data.totalDetails.total),
          });
        })
        .catch((e) => console.log(e))
        .finally(() => {
          setLoading(false);
        });
    }
  }, [user.accessToken]);

  useEffect(() => {
    if (pdfIns.blob && !pdfIns.loading) {
      const reader = new FileReader();
      reader.readAsDataURL(pdfIns.blob);
      reader.onloadend = function () {
        const base64String = reader.result;
        setPreviewFile({
          content: base64String,
          type: pdfIns.blob.type,
          filename: "",
        });
      };
    }
  }, [pdfIns]);

  const submitUserPackageAndPay = () => {
    if (!user.accessToken) {
      return;
    }

    if (user.accessToken && flow.inv && user.package) {
      if (user.package.status === "Active" && !user.package.sid) {
        dispatchFlow({
          type: "update-payment-status",
          payload: {
            accessToken: user.accessToken,
            paymentStatus: false,
          },
        });
        navigate("/vault");
        return;
      }
      if (user.package.sid) {
        const dt = encodeBase64({ s: user.package.sid, p: user.package.pid });
        navigate(`/payment/${dt}`);
        return;
      }
    }

    if (user.accessToken && flow.pkg) {
      setLoading(true);
      if (user.profile) {
        void sendCmpEvent("Mail_4", user.profile.email);
      }

      const invId = flow.inv ? flow.inv.i : undefined;
      const pId = flow.inv ? flow.inv.p : flow.pkg;

      setUserPackage(user.accessToken, pId, invId)
        .then((data) => {
          const { stripeSubscriptionId, stripeSubscriptionStatus, id } = data;
          if (
            stripeSubscriptionId === null &&
            stripeSubscriptionStatus === "Active"
          ) {
            dispatchFlow({
              type: "update-payment-status",
              payload: {
                accessToken: user.accessToken,
                paymentStatus: false,
              },
            });
            navigate("/vault");
          } else {
            const dt = encodeBase64({ s: stripeSubscriptionId, p: id });
            navigate(`/payment/${dt}`);
          }
        })
        .catch((e) => console.log(e))
        .finally(() => {
          setLoading(false);
        });
    }
  };

  if (loading) {
    return (
      <>
        <LoaderScreen />
      </>
    );
  }

  return (
    <>
      <div className="flex flex-col gap-4 px-3 lg:flex-row lg:px-3 xl:gap-20">
        <div className="flex flex-1 flex-col items-center">
          {previewFile && (
            <div
              className="relative mx-auto flex cursor-pointer"
              onClick={() => setPreviewModal(true)}
              id="fardigstall_will_preview"
            >
              <PdfPreview
                file={previewFile.content}
                width={isDesktopView() ? 500 : window.innerWidth - 200}
                theme="border"
              />
            </div>
          )}
          {!isDesktopView() && (
            <Button
              onClick={submitUserPackageAndPay}
              title="Färdigställ ditt testamente"
              theme="white"
              cssClasses="border-none !px-0 text-[15px]"
              id="fardigstall_will_continue"
            />
          )}
        </div>
        <div className="flex flex-1 flex-col justify-center">
          <div className="max-w-[340px]">
            <div className="mb-5 lg:mb-10">
              <RightColumnHeader
                title="Livsplanering"
                subtitle="Färdigställ ditt testamente"
              />
            </div>
            <div className="mb-6 font-intermedium text-theme-primary1">
              Detta ingår i din livsplanering just nu
            </div>
            <div className="mb-8 flex flex-col gap-2 font-interlight text-theme-primary2">
              <div className="flex flex-row gap-2">
                <div className="mt-2 h-[10px] w-[10px] shrink-0 rounded-full bg-theme-neutral3" />
                <div>Leverans av testamente.</div>
              </div>
              <div className="flex flex-row gap-2">
                <div className="mt-2 h-[10px] w-[10px] shrink-0 rounded-full bg-theme-neutral3" />
                <div>
                  Åtkomst till kopia av testamentet i ditt digitala kassaskåp.
                </div>
              </div>
              <div className="flex flex-row gap-2">
                <div className="mt-2 h-[10px] w-[10px] shrink-0 rounded-full bg-theme-neutral3" />
                <div>
                  Förvaring och övervakning av ditt signerade testamente.
                </div>
              </div>
            </div>
            <hr className="mb-8 border-theme-neutral3" />
            <div className="mb-10 flex flex-row items-center justify-between">
              <div className="text-[13px] text-theme-neutral4">
                <div className="font-intermedium">Totalbelopp</div>
                <div>inkl. moms</div>
              </div>
              <div className="font-intermedium text-[18px] italic text-theme-primary1">
                {paket?.price} kr
              </div>
            </div>
          </div>
          <div className="">
            <Button
              onClick={submitUserPackageAndPay}
              title="Färdigställ ditt testamente"
              id="fardigstall_will_continue"
            />
          </div>
        </div>
      </div>
      {previewModal && (
        <Modal onClose={() => setPreviewModal(false)}>
          {previewFile && (
            <div className="flex justify-center">
              <PdfPreview
                file={previewFile.content}
                width={!isDesktopView() ? window.innerWidth - 30 : undefined}
              />
              <div className="absolute top-[240px] w-full lg:top-[340px]">
                <Button
                  onClick={submitUserPackageAndPay}
                  title="Färdigställ ditt testamente"
                  cssClasses="mx-auto max-w-[88%]"
                  id="fardigstall_will_preview_continue"
                />
              </div>
            </div>
          )}
        </Modal>
      )}
    </>
  );
}
