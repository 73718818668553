import {
  createContext,
  Dispatch,
  PropsWithChildren,
  useContext,
  useEffect,
  useReducer,
} from "react";
import { useNavigate } from "react-router-dom";

import config from "../config";
import {
  getProfileInfo,
  getUserPackage,
  updateProfileInfo,
} from "../services/ida";
import { getPartnerByUrl } from "../services/partners";
import {
  conversationKey,
  flowKey,
  getCookie,
  getFromStorage,
  homeRedirectKey,
  partnerKey,
  removeCookie,
  removeFromStorage,
  setInStorage,
  userEmailKey,
  userKey,
} from "../services/storage";
import { dataDeepCopy } from "../services/utils";
import { IdaUserPackageType } from "../types/ida";
import {
  UserActionType,
  UserContextType,
  UserPackageType,
  UserProfileType,
  UserType,
} from "../types/user";
import { useFlowContext } from "./FlowContext";

const websiteUrl: string = config.website_url;

const initialUser: UserType = {
  info: null,
  expDate: 0,
  refreshToken: "",
};

const UserContext = createContext({} as UserContextType);
const UserDispatchContext = createContext(
  null as unknown as Dispatch<UserActionType>
);

const userReducer = (state: UserType, action: UserActionType) => {
  switch (action.type) {
    case "set-data": {
      const age =
        new Date().getFullYear() -
        parseInt(action.payload.personNumber.slice(0, 4));
      return {
        ...state,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
        expDate: action.payload.expDate,
        profile: action.payload.profile,
        info: {
          ...state.info,
          firstName: action.payload.firstName,
          lastName: action.payload.lastName,
          personNumber: action.payload.personNumber,
          age: age,
        },
      };
    }
    case "refresh-token":
      return {
        ...state,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
        expDate: action.payload.expDate,
      };
    case "set-package":
      return {
        ...state,
        package: action.payload,
      };
    case "set-conversation":
      return {
        ...state,
        data: {
          ...state.data,
          conv: action.payload,
        },
      };
    case "set-profile":
      return {
        ...state,
        profile: action.payload,
      };
    case "set-from-storage": {
      const payload = { ...action.payload };
      if (!payload.accessToken) {
        payload.accessToken = null;
      }
      return {
        ...state,
        ...payload,
      };
    }
    case "clear":
      return initialUser;
    default:
      return state;
  }
};

export const UserProvider = ({ children }: PropsWithChildren) => {
  const [user, dispatch] = useReducer(userReducer, initialUser);

  const populateFromStorage = () => {
    const localUser = getFromStorage(userKey, initialUser);
    const data = dataDeepCopy(localUser);

    // force fetch data from api
    // data.accessTokenExpires = 0;

    if (data) {
      dispatch({ type: "set-from-storage", payload: data });
    }
  };
  const saveToStorage = () => {
    setInStorage(userKey, user);
  };
  const checkExpiredToken = () => {
    if (!user.accessToken) {
      return false;
    }
    if (user && Date.now() >= user.expDate) {
      return false;
    }
    return true;
  };
  const fetchPackageData = (accessToken: string) => {
    return getUserPackage(accessToken)
      .then((data: IdaUserPackageType[]) => {
        if (data.length >= 1) {
          const pRaw = data[data.length - 1];
          const payload: UserPackageType = {
            pid: pRaw.id,
            sid: pRaw.stripeSubscriptionId,
            status: pRaw.stripeSubscriptionStatus,
            contracts: pRaw.fenixServices,
            caseId: pRaw.caseId,
            total:
              pRaw.payments && pRaw.payments[0]
                ? parseFloat(pRaw.payments[0].totalDetails.total)
                : 0,
            partner: pRaw.partner ? pRaw.partner.name : "",
            partnerId: pRaw.partner ? pRaw.partner.id : "",
            partnerPackageId: pRaw.partnerPackageId,
          };

          dispatch({
            type: "set-package",
            payload,
          });
          return payload;
        }
      })
      .catch((e) => console.log(e));
  };
  const fetchProfileData = (accessToken: string) => {
    return getProfileInfo(accessToken)
      .then((data: UserProfileType | undefined) => {
        if (data) {
          dispatch({
            type: "set-profile",
            payload: data,
          });
        }
        return data;
      })
      .catch((e) => console.log(e));
  };

  const updateProfile = (accessToken: string, data: UserProfileType) => {
    return updateProfileInfo(accessToken, data)
      .then((data) => {
        if (!data) {
          return;
        }
        dispatch({
          type: "set-profile",
          payload: data,
        });
        return data;
      })
      .catch((e) => console.log(e));
  };
  const logout = () => {
    dispatch({ type: "clear" });
    const redirectUrl = getFromStorage(homeRedirectKey);
    removeFromStorage(userKey);
    removeFromStorage(conversationKey);
    removeFromStorage(userEmailKey);
    removeCookie(userEmailKey);
    removeFromStorage(flowKey);
    window.location.href = redirectUrl
      ? websiteUrl + redirectUrl
      : websiteUrl + "/";
  };

  const obj = {
    user,
    populateFromStorage,
    saveToStorage,
    fetchPackageData,
    fetchProfileData,
    checkExpiredToken,
    updateProfile,
    logout,
  };

  return (
    <UserContext.Provider value={obj}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserContext.Provider>
  );
};

export function useUserContext() {
  return useContext(UserContext);
}

export function useUserDispatchContext() {
  return useContext(UserDispatchContext);
}

export function RequireUserAuth({ children }: PropsWithChildren) {
  const { user, checkExpiredToken, logout } = useUserContext();
  const { flow } = useFlowContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (user.accessToken === undefined) {
      return;
    }
    if (user.accessToken === null) {
      navigate("/login");
      return;
    }
    const isValid = checkExpiredToken();
    if (!isValid) {
      logout();
    }
  }, [user, flow]);

  return <>{children}</>;
}
