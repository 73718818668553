import React from "react";

import { useFlowContext } from "../../context/FlowContext";
import { getPersonsForInheritanceDistribution } from "../../context/helpers/flow";
import {
  checkDepsWithinQuestionField,
  LPA_PERSON_FIELDS,
  PARTNER_TYPES,
} from "../../services/flow";
import {
  dataDeepCopy,
  getPersonFullLabel,
  processQuestionLabel,
} from "../../services/utils";
import { PersonsDistributionValueType } from "../../types/entities";
import {
  FlowField,
  FlowFieldOption,
  FlowItem,
  FlowItemField,
  FlowOrganization,
  FlowPerson,
} from "../../types/flow";
import BlockRadioCheckbox from "../ui/BlockRadioCheckbox";
import CheckboxDistribution from "../ui/CheckboxDistribution";
import Input from "../ui/Input";
import Number from "../ui/Number";
import RadioCheckbox from "../ui/RadioCheckbox";
import Select from "../ui/Select";
import Textarea from "../ui/Textarea";
import QuestionTitle from "./QuestionTitle";

type PropsType = {
  item: FlowItem;
  questions: FlowItem[];
  getFieldValue: (
    item: FlowItem,
    name: string,
    single: boolean,
    index: number
  ) => string[] | string;
  setFieldValue: (
    item: FlowItem,
    field: FlowField,
    value: string | string[],
    index?: number
  ) => void;
  index?: number;
  showErrors: boolean;
};

export function Question({
  item,
  questions,
  getFieldValue,
  setFieldValue,
  index,
  showErrors,
}: PropsType) {
  const { flow, getPersonList } = useFlowContext();

  const getOptionsForPersonsSelect = (field: FlowField, section?: number) => {
    let options = getPersonList(section).reduce((acc, current) => {
      if (
        field.excludePersonTypes &&
        field.excludePersonTypes.includes(current.type)
      ) {
        return acc;
      }
      if (
        field.excludePersonRelations &&
        field.excludePersonRelations.includes(current.relation)
      ) {
        return acc;
      }
      return [
        ...acc,
        { label: getPersonFullLabel(current), value: current.id },
      ];
    }, [] as FlowFieldOption[]);

    if (field.excludeValuesFromName) {
      let valuesToExclude = [] as string[];
      field.excludeValuesFromName.map((v) => {
        const ans = flow.values.find((f) => f.name === v);
        let val = ans ? ans.value : [];

        if (v === "assets-owners") {
          const ppl = flow.assets
            .filter((a) => a.type === "real-estate")
            .reduce((acc, asset) => {
              const aop = asset.data.find(
                (d) => d.name === "asset-owned-persons"
              );
              if (!aop) {
                return acc;
              }
              return [...acc, ...aop.value];
            }, [] as string[]);
          if (ppl.length > 0) {
            val = [...val, ...ppl];
          }
        }

        valuesToExclude = [...valuesToExclude, ...val];
      });

      if (valuesToExclude) {
        options = options.reduce((acc, o) => {
          if (valuesToExclude.includes(o.value)) {
            return acc;
          }
          return [...acc, o];
        }, [] as FlowFieldOption[]);
      }
    }

    if (field.options) {
      options = [...options, ...field.options];
    }

    return options;
  };

  const renderPersonsNumbers = (field: FlowField) => {
    const name = field.name;
    const elNames =
      name === "lpa-persons-numbers"
        ? LPA_PERSON_FIELDS
        : [
            "heirs",
            "inheritance-partner-death-persons",
            "inheritance-your-death-persons",
            "inheritance-partner-death-before",
            "asset-bequeath-persons",
          ];
    const heirsFromFlow = flow.values.filter((el) => elNames.includes(el.name));
    const heirsFromAssets = flow.assets.reduce((acc, a) => {
      const fields = a.data.filter((el) => elNames.includes(el.name));
      if (fields.length) {
        return [...acc, ...fields];
      }
      return acc;
    }, []);

    const heirs = [...heirsFromFlow, ...heirsFromAssets];
    if (!heirs) {
      return null;
    }

    const ids = heirs.reduce((acc, el) => {
      return [...acc, ...el.value];
    }, []);

    const people = ids.reduce((acc, id) => {
      const person = flow.persons.find((p) => p.id === id);
      if (!person || acc.find((a) => a.id === id)) {
        return acc;
      }
      return [...acc, person];
    }, [] as (FlowPerson | FlowOrganization)[]);

    if (!people.length) {
      return null;
    }

    const persons = people.filter((el) => el.type === "person") as FlowPerson[];
    if (persons.length === 0) {
      return;
    }

    const onChangeHandler = (p, val) => {
      const curValues = getFieldValue(item, name, false, p.id);
      const values = curValues.filter((e) => e.id !== p.id);
      setFieldValue(
        item,
        field,
        [...values, { id: p.id, personalNumber: val }],
        p.id
      );
    };

    return (
      <div className="flex flex-col gap-10">
        {persons.map((p) => {
          const v = getFieldValue(item, name, false, p.id).find(
            (e) => e.id === p.id
          )?.personalNumber;
          if (!v) {
            onChangeHandler(p, p.personalNumber);
          }
          return (
            <div key={p.id}>
              <Input
                name={name}
                label={`Personnummer (12 siffror) för ${p.firstName} ${p.lastName}`}
                value={v ? v : p.personalNumber}
                error={getFieldErrors(p.id)}
                onChange={(e) => {
                  onChangeHandler(p, e.currentTarget.value);
                }}
              />
            </div>
          );
        })}
      </div>
    );
  };

  const renderPersonsSelect = (i: FlowItemField, field: FlowField) => {
    const options = getOptionsForPersonsSelect(field, flow.section);
    const type = field.verification === "radio" ? "radio" : "checkbox";
    return (
      <RadioCheckbox
        label={processLabel(i.label)}
        info={i.info}
        name={field.name}
        type={type}
        options={options}
        view="col"
        wrapperClasses="!gap-[8px]"
        rowClasses="border-[1px] border-theme-neutral3 rounded-[4px] p-2"
        value={getFieldValue(item, field.name, false, index)}
        onChange={(e) => {
          const val = e.currentTarget.value;
          if (field.verification === "radio") {
            setFieldValue(item, field, val, index);
            return;
          }
          const curValues = getFieldValue(item, field.name, false, index);

          if (curValues.includes(val)) {
            const index = curValues.indexOf(val);
            curValues.splice(index, 1);
          } else {
            curValues.push(val);
          }
          setFieldValue(item, field, curValues, index);
        }}
        nestedChildren={getNestedChildren(item.id, field.name)}
        error={getFieldErrors(field.name)}
      />
    );
  };

  const renderPersonsDistribution = (i: FlowItemField, field: FlowField) => {
    const displayPersons = getPersonsForDistribution(field);
    if (!displayPersons.length) {
      return;
    }

    const value = getFieldValue(
      item,
      field.name,
      false,
      index
    ) as PersonsDistributionValueType[];

    if (value.length === 0) {
      setFieldValue(
        item,
        field,
        displayPersons.reduce((ap, p) => {
          return [...ap, { [p.value]: 0 }];
        }, []),
        index
      );
      return;
    } else {
      let updateValues = false;
      const newValues = value.reduce((a, v) => {
        const k = Object.keys(v).toString();
        const isInList = displayPersons.find((d) => d.value === k);
        if (!isInList) {
          updateValues = true;
          return a;
        }
        return [...a, v];
      }, []);
      if (updateValues) {
        setFieldValue(item, field, newValues, index);
        return;
      }
    }

    return (
      <CheckboxDistribution
        label={processLabel(i.label)}
        info={i.info}
        name={field.name}
        options={displayPersons}
        wrapperClasses="max-w-[360px]"
        value={value}
        onChange={(id, num) => {
          const curValues = dataDeepCopy(value);

          const ind = curValues.findIndex((cv) => Object.keys(cv).includes(id));
          if (num === undefined) {
            if (ind > -1) {
              curValues.splice(ind, 1);
            } else {
              curValues.push({
                [id]: 0,
              });
            }
          } else {
            curValues[ind][id] = num;
          }

          setFieldValue(item, field, curValues, index);
        }}
        error={getFieldErrors(field.name)}
        desc="<div class='text-[11px] italic font-internormal'>
            Använd +/– knapparna, eller skriv in manuellt genom att klicka i
            siffer-rutan för att fördela procenten.
          </div>"
      />
    );
  };

  const getPersonsForDistribution = (field: FlowField) => {
    const allPersons = getOptionsForPersonsSelect(field);
    const partner = getPersonList(1).find((p) =>
      PARTNER_TYPES.includes(p.relation)
    );

    // show persons only from "partner-share-inheritance-persons" and "heirs"
    const [d, otherPersons] = getPersonsForInheritanceDistribution(
      [...flow.values],
      []
    );
    const selectedPersons = flow.values
      .filter(
        (v) =>
          v.name === "heirs" || v.name === "partner-share-inheritance-persons"
      )
      .reduce((a, i) => {
        return [...a, ...i.value.filter((c) => c !== "other")];
      }, [] as string[]);
    let toDisplayPersons = [
      ...otherPersons.reduce((a, i) => [...a, i.id], [] as string[]),
      ...selectedPersons,
    ];

    // include partner to the list if "protect-partner" question is "yes"
    if (
      partner &&
      !toDisplayPersons.includes(partner.id) &&
      flow.values
        .find((v) => v.name === "protect-partner")
        ?.value.toString() === "yes"
    ) {
      toDisplayPersons = [...toDisplayPersons, partner.id];
    }

    const displayPersons = allPersons.filter((p) =>
      toDisplayPersons.includes(p.value)
    );

    return displayPersons;
  };

  const renderFields = (item: FlowItem, i: FlowItemField) => {
    return i.fields.map((field) => {
      return (
        <div className="w-full" key={`${field.name}`}>
          {field.type === "input" && (
            <Input
              name={field.name}
              info={i.info}
              label={i.fields.length === 1 ? processLabel(i.label) : ""}
              placeholder={field.placeholder}
              value={getFieldValue(item, field.name, true, index)}
              onChange={(e) => {
                setFieldValue(item, field, e.currentTarget.value, index);
              }}
              error={getFieldErrors(field.name)}
            />
          )}
          {field.type === "textarea" && (
            <Textarea
              name={field.name}
              info={i.info}
              label={i.fields.length === 1 ? processLabel(i.label) : ""}
              placeholder={field.placeholder}
              value={getFieldValue(item, field.name, true, index)}
              onChange={(e) => {
                setFieldValue(item, field, e.currentTarget.value, index);
              }}
              error={getFieldErrors(field.name)}
            />
          )}
          {field.type === "select" && (
            <Select
              options={field.options}
              info={i.info}
              label={i.fields.length === 1 ? processLabel(i.label) : ""}
              placeholder={field.placeholder}
              value={getFieldValue(item, field.name, true, index)}
              onChange={(e) =>
                setFieldValue(item, field, e.currentTarget.value, index)
              }
              error={getFieldErrors(field.name)}
            />
          )}
          {field.type === "radio" && (
            <RadioCheckbox
              label={processLabel(i.label)}
              info={i.info}
              name={field.name}
              type={field.type}
              options={field.options}
              view="col"
              wrapperClasses="!gap-[8px]"
              value={getFieldValue(item, field.name, false, index)}
              onChange={(e) =>
                setFieldValue(item, field, e.currentTarget.value, index)
              }
              nestedChildren={getNestedChildren(item.id, field.name)}
              error={getFieldErrors(field.name)}
            />
          )}
          {field.type === "checkbox" && (
            <RadioCheckbox
              label={processLabel(i.label)}
              info={i.info}
              desc={i.desc}
              name={field.name}
              type={field.type}
              options={field.options}
              view="col"
              wrapperClasses="!gap-[8px]"
              value={getFieldValue(item, field.name, false, index)}
              onChange={(e) => {
                const curValues = getFieldValue(item, field.name, false, index);
                const val = e.currentTarget.value;
                if (curValues.includes(val)) {
                  const index = curValues.indexOf(val);
                  curValues.splice(index, 1);
                } else {
                  curValues.push(val);
                }
                setFieldValue(item, field, curValues, index);
              }}
              nestedChildren={getNestedChildren(item.id, field.name)}
              error={getFieldErrors(field.name)}
            />
          )}
          {field.type === "assets-select" && (
            <BlockRadioCheckbox
              label={processLabel(i.label)}
              name={field.name}
              type="checkbox"
              options={field.options}
              view="col"
              wrapperClasses="!gap-[8px]"
              value={getFieldValue(item, field.name, false, index)}
              onChange={(e) => {
                const curValues = getFieldValue(item, field.name, false, index);
                const val = e.currentTarget.value;
                if (curValues.includes(val)) {
                  const index = curValues.indexOf(val);
                  curValues.splice(index, 1);
                } else {
                  curValues.push(val);
                }
                setFieldValue(item, field, curValues, index);
              }}
              error={getFieldErrors(field.name)}
            />
          )}
          {field.type === "number" && (
            <Number
              startValue={() => {
                const v = getFieldValue(item, field.name, true, index);
                return v ? v : 0;
              }}
              maxValue={100}
              minValue={0}
              buttonSize={42}
              onChange={(val) => {
                setFieldValue(item, field, [val], index);
              }}
              percentage={true}
              wrapperClasses="w-[240px]"
              label={processLabel(i.label)}
            />
          )}
          {field.type === "persons-select" && renderPersonsSelect(i, field)}
          {field.type === "persons-numbers" && renderPersonsNumbers(field)}
          {field.type === "persons-distribution" &&
            renderPersonsDistribution(i, field)}
        </div>
      );
    });
  };

  const renderItems = (i: FlowItemField, ind: number) => {
    if (!checkDepsWithinQuestionField(i, flow, item, index)) {
      return null;
    }
    const lbl = processLabel(i.label);
    return (
      <div key={`${item.id}-${ind}`} className="">
        {i.fields.length > 1 && lbl && (
          <div className="mb-2 font-intermedium text-dark-blue">{lbl}</div>
        )}
        <div className="flex flex-row gap-2">{renderFields(item, i)}</div>
      </div>
    );
  };

  const processLabel = (lbl: FlowItemField["label"]) => {
    return processQuestionLabel(lbl);
  };

  const renderGroupTitle = () => {
    if (!item.groupTitle) {
      return null;
    }
    const title = processLabel(item.groupTitle);
    if (title) {
      return <QuestionTitle label={title} name={item.id} />;
    }
  };

  const getNestedChildren = (itemId: string, fieldName: string) => {
    if (!questions) {
      return null;
    }
    const q = questions?.filter(
      (el) =>
        el.nested &&
        el.nested.itemId === itemId &&
        el.nested.fieldName === fieldName
    );

    if (!q || q.length === 0) {
      return null;
    }

    return q.map((qs) => {
      return {
        value: qs.nested.value,
        node: qs.items.map((i) => {
          return renderFields(qs, i);
        }),
      };
    });
  };

  const getFieldErrors = (name: string): string => {
    if (!showErrors || !flow.errors) {
      return "";
    }
    if (item.multiple) {
      const n = `${item.multiple}-${index}-${name}`;
      return flow.errors[n] ?? "";
    }
    return flow.errors[name] ?? "";
  };

  const classes =
    item.type === "input-group" ? "bg-[#F3F0E6] p-[18px] lg:p-[24px]" : "";
  return (
    <div className={!item.multiple ? item.wrapperClasses : ""}>
      {renderGroupTitle()}
      <div className={`flex flex-col gap-5 ${classes}`} key={item.id}>
        {item.items.map(renderItems)}
      </div>
    </div>
  );
}
