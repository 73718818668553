import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useFlowContext, useFlowDispatchContext } from "../context/FlowContext";
import { useUserContext } from "../context/UserContext";
import { getCheckIcon, getLoadingAnimatedIcon } from "../services/icons";
import { setUserPackage } from "../services/ida";
import {
  encodeBase64,
  isLPAEnabled,
  isPreplanEnabled,
} from "../services/utils";
import { UserPackageType } from "../types/user";
import Button from "./Button";
import { getPartnerByUrl } from "../services/partners";

type PropType = {
  packageData: UserPackageType | undefined;
};

export default function VaultHero({ packageData }: PropType) {
  const [status, setStatus] = useState("default");
  const [header, setHeader] = useState("");
  const [showLog, setShowLog] = useState(false);
  const [description, setDescription] = useState("");
  const [cta, setCta] = useState("");

  const { flow, createNewSession } = useFlowContext();
  const { user, fetchPackageData } = useUserContext();
  const navigate = useNavigate();
  const dispatchFlow = useFlowDispatchContext();

  useEffect(() => {
    if (
      havePayedPackage() &&
      flow.section === 2 &&
      (flow.status === "will-preview" || flow.status === "finished")
    ) {
      setHeader("Din betalning är klar");
      setDescription(
        "Nu har vi fått din betalning och du kan i lugn och ro fortsätta med din livsplanering."
      );
      setCta("Fortsätt livsplanera");
      setStatus("payment-successful");
      return;
    }
    if (
      havePrePaidPackage() &&
      flow.section === 2 &&
      (flow.status === "will-preview" || flow.status === "finished")
    ) {
      setHeader("Besvara frågorna i tredje kapitlet");
      setDescription(
        "Klicka på knappen för att gå vidare till nästa del av din livsplanering."
      );
      setCta("Fortsätt livsplanera");
      setStatus("payment-prepaid-successful");
      return;
    }
    if (flow.status === "init") {
      setHeader("Välkommen till ditt digitala kassaskåp");
      setDescription(
        "Här förvarar du dina avtal, tryggt, säkert och med enkel åtkomst genom ditt Mobila BankID. Vi övervakar även avtalen i ditt kassaskåp så att de hamnar i rätt händer när de väl ska verkställas."
      );
      setCta("Börja livsplanera");
      return;
    }
    // flow
    if (flow.section === 1 && flow.status === "started") {
      setHeader("Välkommen tillbaka");
      setDescription(
        "Klicka på knappen för att fortsätta med din livsplanering."
      );
      setCta("Fortsätt livsplanera");
      setStatus("in-progress");
      return;
    }
    if (flow.section === 1 && flow.status === "finished") {
      setHeader("Välkommen tillbaka");
      setDescription("Klicka på knappen för att påbörja ditt testamente.");
      setCta("Fortsätt livsplanera");
      setStatus("finished");
      return;
    }
    if (!packageData || haveIncompletePackage()) {
      setHeader("Slutför din betalning");
      setDescription(
        "Du är ett steg närmare lugn och ro. Slutför betalningen för att färdigställa dina avtal."
      );
      setCta("Fortsätt livsplanera");
      setStatus("payment-to-complete");
      return;
    }
    if (
      flow.status === "will-preview" &&
      (!packageData || haveActivePackage())
    ) {
      setHeader("Välkommen tillbaka");
      setDescription(
        "Klicka på knappen för att fortsätta med din livsplanering."
      );
      setCta("Fortsätt livsplanera");
      setStatus("in-progress");
      return;
    }
    if (
      flow.section === 2 &&
      (flow.status === "started" ||
        flow.status === "finished" ||
        (flow.status === "will-preview" && !packageData))
    ) {
      setHeader("Välkommen tillbaka");
      setDescription(
        "Klicka på knappen för att slutföra ditt testamente och gå vidare till nästa del."
      );
      setCta("Fortsätt livsplanera");
      setStatus("in-progress");
      return;
    }

    if (flow.section === 3 && flow.status === "started") {
      setHeader("Välkommen tillbaka");
      setDescription("Klicka på knappen för att fortsätta din livsplanering.");
      setCta("Fortsätt livsplanera");
      setStatus("in-progress");
      return;
    }
    if (
      flow.section === 3 &&
      flow.status === "finished" &&
      isLPAEnabled(flow)
    ) {
      setHeader("Välkommen tillbaka");
      setDescription("Klicka på knappen för att påbörja din framtidsfullmakt.");
      setCta("Fortsätt livsplanera");
      setStatus("in-progress");
      return;
    }
    if (
      flow.section === 3 &&
      flow.status === "finished" &&
      isPreplanEnabled(flow)
    ) {
      setHeader("Välkommen tillbaka");
      setDescription(
        "Klicka på knappen för att fortsätta med din livsplanering."
      );
      setCta("Fortsätt livsplanera");
      setStatus("in-progress");
      return;
    }
    if (flow.section === 3 && flow.status === "finished") {
      setHeader("Välkommen tillbaka");
      setDescription("Klicka på knappen för att färdigställa ditt testamente.");
      setCta("Slutför Livsplaneringen");
      setStatus("in-progress");
      return;
    }

    if (flow.section === 4 && flow.status === "started") {
      setHeader("Välkommen tillbaka");
      setDescription(
        "Klicka på knappen för att slutföra din framtidsfullmakt och gå vidare till den sista delen."
      );
      setCta("Fortsätt livsplanera");
      setStatus("in-progress");
      return;
    }
    if (
      flow.section === 4 &&
      flow.status === "finished" &&
      isPreplanEnabled(flow)
    ) {
      setHeader("Välkommen tillbaka");
      setDescription(
        "Klicka på knappen för att fortsätta med din livsplanering."
      );
      setCta("Fortsätt livsplanera");
      setStatus("in-progress");
      return;
    }
    if (flow.section === 4 && flow.status === "finished") {
      setHeader("Välkommen tillbaka");
      setDescription(
        "Klicka på knappen för att slutföra din framtidsfullmakt och gå vidare till den sista delen."
      );
      setCta("Fortsätt livsplanera");
      setStatus("in-progress");
      return;
    }

    if (flow.section === 5 && flow.status === "started") {
      setHeader("Välkommen tillbaka");
      setDescription(
        "Du är ett steg från en färdig Livsplanering. Klicka på knappen för att slutföra sina svar till efterlevande."
      );
      setCta("Slutför Livsplaneringen");
      setStatus("in-progress");
      return;
    }

    if (flow.status === "set-edit") {
      setHeader("Välkommen tillbaka");
      setDescription(
        "Klicka på knappen för att fortsätta med din livsplanering."
      );
      setCta("Fortsätt livsplanera");
      setStatus("in-progress");
      return;
    }
    /*
     *
     * OLD
     *
     * */
    if (flow.section === 100 && flow.status === "started") {
      setHeader("Välkommen tillbaka");
      setDescription("Klicka på knappen för att slutföra din livsplanering.");
      setCta("Färdigställ testamente");
      setStatus("in-progress");
      return;
    }
    if (flow.section === 102 && flow.status === "started") {
      setHeader("Välkommen tillbaka");
      setDescription("Klicka på knappen för att slutföra din livsplanering.");
      setCta("Färdigställ Framtidsfullmakt");
      setStatus("in-progress");
      return;
    }
    if (
      (flow.section === 100 && flow.status === "finished") ||
      (flow.section === 102 && flow.status === "finished") ||
      (flow.section === 5 && flow.status === "finished") ||
      (flow.section === 101 && flow.status === "started")
    ) {
      setHeader("Ditt testamente är redo för signering");
      
      // For the "dalarna", "convendum" and "spf-seniorerna" packages, we need to show a different text when pushing to sign the will
      const dalarnaPackage = getPartnerByUrl("dalarna")?.pkg;
      const convendumPackage = getPartnerByUrl("convendum")?.pkg;
      const spfPackage = getPartnerByUrl("spf-seniorerna")?.pkg;
      if (
        user.package &&
        user.package.partnerPackageId &&
        (user.package.partnerPackageId === dalarnaPackage || user.package.partnerPackageId === convendumPackage || user.package.partnerPackageId === spfPackage)
      ) {
        setDescription(
          "Glöm inte att ditt testamente måste signeras för att vara giltigt. Klicka på knappen nedan för att bekräfta ditt testamente så skickar vi testamentet till den adress du har angivit. Utskick av testamente med förfrankerat kuvert för retur till Fenix Family och säker förvaring och övervakning under första året: 199 kronor. Säker förvaring och övervakning av testamente efter första året: 99 kronor per år."
        );
      } else {
        setDescription(
          "Glöm inte att ditt testamente måste signeras för att vara giltigt. Klicka på knappen nedan för att bekräfta ditt testamente så skickar vi testamentet till den adress du har angivit."
        );
      }

      setCta("Få testamentet hemskickat");
      setStatus("will-to-sign");
      return;
    }
    if (flow.section === 101 && flow.status === "finished") {
      setHeader("Ditt testamente är snart i tryggt förvar");
      setDescription(
        "Vi har skickat ditt testamente till den adress du har angivit. Glöm inte att skicka tillbaka testamentet till oss när du har signerat det."
      );
      setCta("Gå till dokument");
      setStatus("will-to-sign");
      return;
    }
  }, [flow, packageData]);

  const haveActivePackage = () =>
    packageData && packageData.status === "Active";
  const havePayedPackage = () =>
    packageData && packageData.status === "Active" && packageData.sid;
  const havePrePaidPackage = () =>
    packageData && packageData.status === "Active" && !packageData.sid;
  const haveIncompletePackage = () =>
    packageData &&
    (packageData.status === "Incomplete" ||
      packageData.status === "IncompleteExpired");

  const beginFlowHandler = async () => {
    if (flow && flow.inv && flow.inv.i) {
      const invId = flow.inv.i;
      const pId = flow.inv ? flow.inv.p : flow.pkg;

      void setUserPackage(user.accessToken, pId, invId)
        .then(() => {
          void fetchPackageData(user.accessToken);
        })
        .catch((e) => console.log(e));
    }
    dispatchFlow({
      type: "set-status",
      payload: { accessToken: user.accessToken, status: "started" },
    });
    await createNewSession();
    navigate("/flow");
  };

  const payOrSelectPackage = () => {
    if (!packageData || packageData.status === "IncompleteExpired") {
      dispatchFlow({
        type: "set-status",
        payload: { accessToken: user.accessToken, status: "will-preview" },
      });
      setTimeout(() => {
        navigate("/flow");
      }, 300);
    } else {
      const dt = encodeBase64({ s: packageData?.sid, p: packageData?.pid });
      navigate(`/payment/${dt}`);
    }
  };

  const renderHeader = () => {
    return (
      <div className="lea mt-0 mb-3 max-w-2xl font-interbold text-[28px] italic leading-[30px] text-dark-blue lg:mb-5 lg:text-[48px] lg:leading-[52px]">
        {header}
      </div>
    );
  };

  const renderDesc = () => {
    return <div className="mb-5 max-w-2xl font-interlight">{description}</div>;
  };

  const renderPaymentProcessHeader = () => {
    return (
      <div className="flex flex-col items-center">
        {getLoadingAnimatedIcon("#0C2632", "140", "100%")}
        <div className="font-interlight text-theme-primary1">
          Din betalning bearbetas
        </div>
      </div>
    );
  };

  const renderPaymentSuccessHeader = () => {
    return (
      <div className="mb-5 flex flex-row items-center gap-3">
        {getCheckIcon()}
        <div className="font-interbold uppercase tracking-[0.25em]">
          din betalning är klar
        </div>
      </div>
    );
  };

  const renderLogForButton = (msg: string) => {
    if (!showLog) {
      return;
    }
    return <div>{msg}</div>;
  };

  if (flow.paymentStatus) {
    return renderPaymentProcessHeader();
  }

  return (
    <div>
      {status === "default" && (
        <>
          {renderHeader()}
          {renderDesc()}
          <div>
            <Button
              title={cta}
              onClick={beginFlowHandler}
              id="vault_begin_lifeplanning"
            />
          </div>
        </>
      )}
      {(status === "in-progress" || status === "finished") && (
        <>
          {renderHeader()}
          {renderDesc()}
          <div>
            <Button
              title={cta}
              onClick={() => {
                navigate("/flow");
              }}
              id="vault_continue_lifeplanning"
            />
            {renderLogForButton("redirect to flow")}
          </div>
        </>
      )}
      {status === "payment-to-complete" && (
        <>
          {renderHeader()}
          {renderDesc()}
          <div>
            <Button
              title={cta}
              onClick={payOrSelectPackage}
              id="vault_complete_payment"
            />
            {renderLogForButton('set "will-preview" or redirect to payment')}
          </div>
        </>
      )}
      {status === "payment-successful" && (
        <>
          {renderPaymentSuccessHeader()}
          {renderDesc()}
          <div>
            <Button
              title={cta}
              onClick={() => {
                dispatchFlow({
                  type: "set-section-start",
                  payload: { accessToken: user.accessToken, section: 3 },
                });
                navigate("/flow");
              }}
              id="vault_continue_lifeplanning_after_payment"
            />
            {renderLogForButton("set section 3 / payment-successful")}
          </div>
        </>
      )}
      {status === "payment-prepaid-successful" && (
        <>
          {renderHeader()}
          {renderDesc()}
          <div>
            <Button
              title={cta}
              onClick={() => {
                dispatchFlow({
                  type: "set-section-start",
                  payload: { accessToken: user.accessToken, section: 3 },
                });
                navigate("/flow");
              }}
              id="vault_continue_lifeplanning_after_prepaid"
            />
            {renderLogForButton("set section 3 / payment-prepaid-successful")}
          </div>
        </>
      )}
      {status === "will-to-sign" && (
        <>
          {renderHeader()}
          {renderDesc()}
          <div>
            <Button
              title={cta}
              onClick={() => {
                navigate("/documents");
              }}
              id="vault_continue_to_sign_will"
            />
            {renderLogForButton("redirect to documents")}
          </div>
        </>
      )}
    </div>
  );
}
